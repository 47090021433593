.ant-table-tbody>tr>td {
  padding: 0px 16px 0px 16px !important;
}

.ant-spin-dot-item {
  background-color: #fff !important;
}

p {
  margin: 0;
}

.ant-table-tbody > tr > td {
  height: 5vh;
  padding: 4px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonContainer > p {
  margin-bottom: 12px;
  font-size: .8vw;
}

.buttonStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #39B13D;
  font-size: .8vw;
  color: #fff;
  height: 2vw;
  width: 11vw;
  font-weight: 400;
  border-color: transparent;
}

.buttonStyle:hover {
  color: #ffffffc4 !important;
}